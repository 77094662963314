<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 mt-10 lg:px-10 py-10 pt-0">
            <form @submit.prevent="sendForm">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Email</label>
                <input v-model="email" type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email" />
              </div>

              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Password</label>
                <input v-model="password" type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password" />
              </div>
              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit">Login</button>
              </div>
              <transition name="slice" mode="out-in" enter-active-class="slideInLeft" leave-active-class="slideOutRight">
                <p v-if="hasError" class="text-red-500 mt-10">Login ou senha incorretos</p>
              </transition>
            </form>
          </div>
        </div>
        <!-- Furute implementation -->
        <!-- <div class="flex flex-wrap mt-6 relative"> -->
        <!--   <div class="w-1/2"> -->
        <!--     <a href="javascript:void(0)" class="text-blueGray-200"> -->
        <!--       <small>Forgot password?</small> -->
        <!--     </a> -->
        <!--   </div> -->
        <!--   <div class="w-1/2 text-right"> -->
        <!--     <router-link to="/auth/register" class="text-blueGray-200"> -->
        <!--       <small>Create new account</small> -->
        <!--     </router-link> -->
        <!--   </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
const axios = require('axios').default;
import { BASE_URL } from '@/config'

export default {
  data() {
    return {
      email: "",
      password: "",
      hasError: false,
    }
  },
  created() {
    const isLoggedIn = Boolean(this.$store.state.access_token && this.$store.state.refresh_token)
    if (isLoggedIn) {
      this.$router.push({ path: '/admin/dashboard' })
    }

  },
  methods: {
    sendForm() {
      this.hasError = false

      if (this.password.length < 4 || this.email.length < 4) {
        this.hasError = true
        return
      }

      axios.post(BASE_URL + "token/", {
        email: this.email, password: this.password
      })
        .then((res) => {
          this.$store.dispatch('saveCredentials', {
            access_token: res.data.access,
            refresh_token: res.data.refresh
          }).then(() => {
            this.$router.push({ path: '/admin/dashboard' })
          })
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.hasError = true
          } else {
            console.log(error)
          }
        })
        ;
      setTimeout(() => {
        this.hasError = false
      }, 3000);
    }
  },
}
</script>
<style>
.text-red-500 {
  color: #ef4444;
}
</style>

