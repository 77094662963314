const axios = require('axios').default;

import router from './router'
import { BASE_URL } from './config'

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function(error) {

    console.log(error.response)
    const originalRequest = error.config;
    if (typeof error.response === 'undefined') {
      router.push('auth/login')
      return Promise.reject(error);
    }

    if (
      error.response.status === 403 &&
      originalRequest.url === BASE_URL + 'token/refresh/'
    ) {
      router.push('auth/login')
      return Promise.reject(error);
    }
    if (
      error.response.status === 403
    ) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken === "undefined") {
        router.push('auth/login')
        return Promise.reject(error);
      }
      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access_token', response.data.access);

              axiosInstance.defaults.headers['Authorization'] =
                'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] =
                'JWT ' + response.data.access;

              const total_errors = localStorage.getItem('total_errors') ? parseInt(localStorage.getItem('total_errors')) : 0;
              localStorage.setItem('total_errors', total_errors + 1)
              if (localStorage.getItem('total_errors') > 200) {
                localStorage.clear('total_errors')
                localStorage.clear('access_token')
                localStorage.clear('refresh_token')
                router.push('auth/login')
              }
              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log('Refresh token is expired', tokenParts.exp, now);
          localStorage.clear('access_token')
          localStorage.clear('refresh_token')
          router.push('auth/login')
          return Promise.reject(error);
        }
      } else {
        console.log('Refresh token not available.');
        router.push('auth/login/');
      }
    }
    // specific error handling done elsewhere
    if (error.response.status == 400) {
      return Promise.reject(error);
    } else {
      alert("Um erro aconteceu")
      return Promise.reject(error);
    }

  }
);

export default axiosInstance
