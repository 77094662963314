<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12 px-4">
      <div class="flex flex-col">
        <div v-if="alertOpen" class="text-white px-6 py-4 rounded relative mb-4 bg-emerald-500">
          <span class="text-xl inline-block mr-5 align-middle">
            <i class="fas fa-check"></i>
          </span>
          <span class="inline-block align-middle ml-2 mr-8">
            <b class="capitalize">Salvo!</b> informações atualizadas
          </span>
          <button
            class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            v-on:click="closeAlert()">
            <span>×</span>
          </button>
        </div>
      </div>
      <div v-if="errors.non_field_errors" class="flex flex-col">
        <div class="text-white px-6 py-4 rounded relative mb-4 error">
          <span class="text-xl inline-block mr-5 align-middle">
            <i class="fas fa-x"></i>
          </span>
          <span class="inline-block align-middle ml-2 mr-8">
            <b class="capitalize">Erro!</b> {{ errors.non_field_errors[0] }}
          </span>
          <button
            class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            v-on:click="closeAlert()">
            <span>×</span>
          </button>
        </div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{{ user.name }} {{ user.last_name }}</h6>
            <button @click="save"
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button">Salvar</button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informações gerais</h6>

            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Prestador</label>
                  <Multiselect placeholder="Pesquise por um prestador" noOptionsText="Nenhuma prestador selecionado"
                    v-model="user.provider" :filter-results="false" :min-chars="1" :resolve-on-load="false" :delay="0"
                    :searchable="true" :options="async function (query) {
                      return fetchData(query, 'providers')
                    }" />

                  <p class=" help is-danger" v-for="error in errors.provider" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Procedimento</label>
                  <Multiselect placeholder="Pesquise por um procedimento"
                    noOptionsText="Nenhuma procedimento selectionado" v-model="user.procedure" :filter-results="false"
                    :min-chars="1" :resolve-on-load="false" :delay="0" :searchable="true" :options="async function (query) {
                      return fetchData(query, 'procedures')
                    }" />
                  <p class="help is-danger" v-for="error in errors.procedure" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Preço
                    Custo</label>
                  <money3 v-bind="config"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="user.price_cost" v-bind:class="{ 'has-error': errors.price_cost }" />
                  <p class="help is-danger" v-for="error in errors.price_cost" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Preço
                    Venda</label>
                  <money3 v-bind="config"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="user.price_sell" v-bind:class="{ 'has-error': errors.price_sell }" />
                  <p class="help is-danger" v-for="error in errors.price_shell" :key="error">{{ error }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import _ from 'lodash'
import axios from '@/axios'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      endpoint: "procedures-provider/",
      userId: "",
      user: {},
      errors: {},
      alertOpen: false,
      config: {
        decimal: ",",
        disableNegative: true,
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      }
    }
  },
  methods: {
    fetchData: _.debounce(async function (query, endpoint) {
      if (query) {
        const response = await axios.get(`/${endpoint}/?name=${query}`)
        return response.data.results.map((item) => {
          return { value: item.id, label: item.name }
        })
      }
    }, 200),
    closeAlert: function () {
      this.alertOpen = false;
    },
    save: function () {
      axios.post(this.endpoint, { ...this.user })
        .then((res) => {
          this.userId = res.data.id
          this.errors = {}
          this.alertOpen = true
          setTimeout(() => {
            this.alertOpen = false
            this.$router.push({ name: 'procedures-provider-details', params: { id: this.userId } })
          }, 2000);

        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errors = err.response.data
          }
        })
    }
  },
  created() {
    // this.userId = this.$route.params.id
    // axios.get(`user/${this.userId}/`).then((res) => {
    //   this.user = res.data
    // })
  }
};
</script>
<style>
.is-danger {
  color: #eb254a;
}

.error {
  background-color: #eb254a;
}

.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
