<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <div class="relative flex flex-col bg-white min-w-0 break-words w-full mb-6 shadow-lg rounded">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="flex flex-wrap">
            <h3 class="w-full px-4 flex-1 font-semibold text-lg text-blueGray-700 mb-6">Cobrança Usuário da Clínica</h3>
            <div class="px-4 flex-0">
              <button style="border-radius: 50%;"
                class="bg-emerald-500 mb-2 text-white active:bg-emerald-600 font-bold uppercase text-base px-3 py-2 rounded shadow-md hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content:flex-end;">
            <input type="text" v-model="search" v-on:keyup="doSearch" placeholder="pesquisar..."
              class="w-full border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring" />
          </div>
        </div>
        <div class="block w-full overflow-x-auto">
          <!-- Projects table -->
          <Loading v-bind:isLoading="isLoading" />
          <div v-if="invoice.length == 0 && isLoading == false">
            <h3 class="w-full px-4 flex-1 font-semibold text-lg text-blueGray-700 mb-6">Nenhuma cobraça gerada</h3>
          </div>
          <table v-if="isLoading == false && invoice.length != 0"
            class="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Nome</th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Total</th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Editar</th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  PAGO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invo in invoice" :key="invo.id">
                <th
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span class="ml-3 font-bold text-blueGray-600">
                <th>{{ invo.user_clinic_name }}</th>
                </span>
                </th>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{{
                  convertMoney(invo.total) }}</td>

                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <router-link :to="{
                    name: 'InvoiceUserClinicDetails', params: { id: invo.id }
                  }">
                    <button
                      class="text-blueGray-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button">
                      <i class="fas fa-pen-to-square"></i>
                    </button>
                  </router-link>
                </td>
                <td v-if="invo.payed_at"
                  class="border-t-0 px-6 align-middle  border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex items-center">
                  <i class="fas fa-check" style="color: green; font-size: 0.95rem;"></i>
                </td>
                <td v-else
                  class="border-t-0 px-6 align-middle  border-l-0 border-r-0 whitespace-nowrap p-4 text-left flex items-center">
                  <i class="fas fa-x red " style="color: red; font-size: 0.95rem;"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="border-b-1 border-blueGray-300" />
          <div class="rounded-t ml-3 mb-0 px-4 py-3 border-0">
            <button v-if="previousPage" @click="navegate(previousPage)"
              class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button">
              <i class="fas fa-arrow-left"></i> anterior
            </button>
            <button @click="navegate(nextPage)" v-if="nextPage"
              class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button">
              <i class="fas fa-arrow-right"></i> próximo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import _ from 'lodash'
import axios from "../../axios"
import toMoney from "../../store/money"
import Loading from '@/components/Commons/Loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      endpoint: "invoice-user-clinic/",
      invoice: [],
      nextPage: "",
      previousPage: "",
      search: "",
      isLoading: true
    }
  },
  created() {
    axios.get(this.endpoint).then((res) => {
      this.invoice = res.data.results
      this.previousPage = res.data.previous
      this.nextPage = res.data.next
      this.isLoading = false
    })
  },
  methods: {

    navegate: function (page) {
      axios.get(page).then((res) => {
        this.invoice = res.data.results
        this.previousPage = res.data.previous
        this.nextPage = res.data.next
      })

    },
    convertMoney: function (money) {
      return toMoney(money)
    },
    doSearch: _.debounce(function () {
      if (this.search.length == 0) {
        axios.get(this.endpoint).then((res) => {
          this.invoice = res.data.results
        })
      }
      if (this.search.length > 3) {
        console.log(this.search)
        axios.get(this.endpoint + `?name=${this.search}`).then((res) => {
          this.invoice = res.data.results
        })

      }
    }, 500)
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
<style>
.text-red-600 {
  background-color: #dc2626;
}
</style>
