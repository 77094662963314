import { createStore } from 'vuex'


// Create a new store instance.
const user = createStore({
  state() {
    return {
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token'),

    }
  },
  mutations: {
    doLoging(state, { access_token, refresh_token }) {
      state.access_token = access_token
      state.refresh_token = refresh_token
    }
  },
  actions: {
    saveCredentials({ commit, state }, credentials) {
      console.log(commit)
      state.access_token = credentials.access_token
      state.refresh_token = credentials.refresh_token
      localStorage.setItem('access_token', credentials.access_token);
      localStorage.setItem('refresh_token', credentials.refresh_token);
    },
    initializeCredentials({ commit, state }) {
      console.log(commit)
      console.log("verificando credentials")
      const access_token = localStorage.getItem('access_token')
      const refresh_token = localStorage.getItem('refresh_token')
      if (access_token) {
        state.access_token = access_token
      }
      if (refresh_token) {
        state.refresh_token = refresh_token
      }

    }
  }
})

export default user
