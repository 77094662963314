<template>
  <div>
    <div class="flex flex-col">
      <div v-if="alertOpen" class="text-white px-6 py-4 rounded relative mb-4 bg-emerald-500">
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-check"></i>
        </span>
        <span class="inline-block align-middle ml-2 mr-8">
          <b class="capitalize">Confirmado</b> informações atualizadas
        </span>
        <button
          class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
          v-on:click="closeAlert()">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">{{ invoice.user_clinic_name }} - {{
            invoice.transfer_forwardings[0].clinic.name }}</h6>
          <button v-if="showButton" @click="save"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button">Confirmar pagamento</button>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informações</h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Nome</label>
                <input type="text" disabled
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="invoice.user_clinic_name" />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">
                  Porcentagem
                </label>
                <input type="text" disabled
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="invoice.percentage" />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">
                  Dia Do vencimento
                </label>
                <input type="text" disabled
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="invoice.formated_due_date" />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Total à
                  pagar</label>
                <input type="text" disabled
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-bind:value="convertMoney(invoice.total)" />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Data do
                  pagamento</label>
                <datepicker locale="pt-BR" format="dd/MM/yyyy HH:mm:ss" placeholder="Seleciona a data do pagamento"
                  v-model="invoice.payed_at">
                </datepicker>
                <p class="help is-danger" v-for="error in errors.payed_at" :key="error">{{ error }}</p>
              </div>
            </div>
          </div>
          <hr class="mt-6 border-b-1 border-blueGray-300" />
          <table class="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Procedimento
                </th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Clinica
                </th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Paciente</th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Data efetuado
                </th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 border-blueGray-100">
                  Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in invoices" :key="invoice.id">
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ invoice.procedure_provider.procedure.name }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ invoice.clinic.name }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ invoice.patient.name }} {{ invoice.patient.last_name }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ invoice.formated_confirmed_at }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  R$ {{ convertMoney(invoice.amount_cost) }}
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td class="text-right">
                  Total
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ convertMoney(sum_amount) }}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from '@/axios'
import states from '@/store/BrazilianStates'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { toBrDate } from '@/utils'
import toMoney from '@/store/money'


export default {
  components: {
    Datepicker
  },
  data() {
    return {
      invoiceId: "",
      invoice: {},
      errors: {},
      alertOpen: false,
      states: states,
      showButton: true,
      is_payed: false,
      sum_amount: 0
    }
  },
  methods: {
    convertMoney: function (money) {
      return toMoney(money)
    },
    closeAlert: function () {
      this.alertOpen = false;
    },
    save: function () {
      if (this.invoice.payed_at == null) {
        this.errors = { payed_at: ["Campo obrigatório"] }
        return
      }
      const payed_at = new Date(this.invoice.payed_at.getTime()).toLocaleString('pt-br')
      axios.patch(`invoice-user-clinic/${this.invoiceId}/`, { payed_at: payed_at })
        .then(() => {
          this.errors = {}
          this.alertOpen = true
          this.invoice.payed_at = payed_at
          this.showButton = false
          setTimeout(() => {
            this.alertOpen = false
            window.location.reload()
          }, 2000);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errors = err.response.data
          }
        })
    }
  },
  created() {

    this.invoiceId = this.$route.params.id
    axios.get(`invoice-user-clinic/${this.invoiceId}/`).then((res) => {
      this.invoice = res.data
      this.sum_amount = res.data.transfer_forwardings.reduce((acc, invoice) => acc + Number(invoice.amount_cost), 0)
      this.is_payed = res.data.payed_at != null
      this.invoice.formated_due_date = toBrDate(this.invoice.due_date,)
      this.invoices = res.data.transfer_forwardings.map((invoice) => {
        invoice.formated_confirmed_at = toBrDate(invoice.confirmed_at, {})
        return invoice
      });
      this.showButton = this.invoice.payed_at == null
    })
  }
};
</script>
<style>
.is-danger {
  color: #eb254a;
}

.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
