<template>
  <div class="relative w-full mb-3">
    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">{{ fieldName }}</label>
    <input v-bind:type=type
      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      :value="modelValue" @input="updateValue" v-bind:class="{ 'has-error': errors.length > 0 }" />

    <p class="help is-danger" v-for="error in errors" :key="error">{{ error }}</p>

  </div>
</template>
<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    fieldName: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      default: '',
      required: true
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
}
</script>
<style>
.is-danger {
  color: #eb254a;
}

.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
