<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">
        <span
          class="border-2 w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
        >
          <img
            alt="..."
            class="w-full rounded-full align-middle border-none shadow-lg"
            :src="image"
          />
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-on:mouseleave="this.toggleDropdown"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <!-- <a -->
      <!--   href="javascript:void(0);" -->
      <!--   class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700" -->
      <!-- >Action</a> -->
      <!-- <a -->
      <!--   href="javascript:void(0);" -->
      <!--   class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700" -->
      <!-- >Another action</a> -->
      <!-- <a -->
      <!--   href="javascript:void(0);" -->
      <!--   class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700" -->
      <!-- >Something else here</a> -->
      <!-- <div class="h-0 my-2 border border-solid border-blueGray-100" /> -->
      <a
        href="javascript:void(0);"
        @click="logout"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >Sair</a>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

import api from "../../axios"

import image from "@/assets/img/user.png";

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      image: image,
    };
  },
  methods: {
    logout: function () {
      const refresh = localStorage.getItem("refresh_token")
      api.post("token/blacklist/", { refresh }).then(() => {
        localStorage.clear('access_token')
        localStorage.clear('refresh_token')
        this.$router.push('auth/login')
      })

    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
