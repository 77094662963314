<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <div class="relative flex flex-col bg-white min-w-0 break-words w-full mb-6 shadow-lg rounded">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="flex flex-wrap">
            <h3 class="w-full px-4 flex-1 font-semibold text-lg text-blueGray-700 mb-6">Usuários provedores</h3>
            <div class="px-4 flex-0">
              <router-link :to="{
                name: 'user-provider-new'
              }">
                <button style="border-radius: 50%;"
                  class="bg-emerald-500 mb-2 text-white active:bg-emerald-600 font-bold uppercase text-base px-3 py-2 rounded shadow-md hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button">
                  <i class="fas fa-plus"></i>
                </button>
              </router-link>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; justify-content:flex-end;">
            <Multiselect @select="show" v-model="value" placeholder="Pesquise por um provedor"
              noOptionsText="Nenhuma provedor" :filter-results="false" :min-chars="1" :resolve-on-load="false"
              :delay="0" :searchable="true" :options="async function (query) {
                return fetchProvedor(query) // check JS block for implementation
              }" />
          </div>
        </div>
        <div class="block w-full overflow-x-auto">
          <!-- Projects table -->
          <table class="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Nome</th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Administrador</th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Editar</th>
                <th
                  class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 border-blueGray-100">
                  Deletar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <th
                  class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span class="ml-3 font-bold text-blueGray-600">
                <th>{{ user.name }}</th>
                </span>
                </th>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <template v-if="user.is_admin">
                    <i class="fas fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-x"></i>
                  </template>
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <router-link :to="{
                    name: 'user-provider-details', params: { id: user.id }
                  }">
                    <button
                      class="text-blueGray-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button">
                      <i class="fas fa-pen-to-square"></i>
                    </button>
                  </router-link>
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <button @click="deleteUser(user.id)"
                    class="text-with bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-blueGray-600 text-red-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button">
                    <i class="fas fa-x"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr class="border-b-1 border-blueGray-300" />
          <div class="rounded-t ml-3 mb-0 px-4 py-3 border-0">
            <button v-if="previousPage" @click="navegate(previousPage)"
              class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button">
              <i class="fas fa-arrow-left"></i> anterior
            </button>
            <button @click="navegate(nextPage)" v-if="nextPage"
              class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button">
              <i class="fas fa-arrow-right"></i> próximo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "../../axios"
import _ from 'lodash'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      endpoint: "users-provider/",
      users: [],
      nextPage: "",
      previousPage: "",
      search: "",
      value: null,
    }
  },
  created() {
    this.value = ""
  },
  methods: {
    fetchProvedor: _.debounce(async function (query) {
      if (query) {
        const response = await axios.get("/providers/?name=" + query)
        return response.data.results.map((item) => {
          return { value: item.id, label: item.name }
        })
      }
    }, 200),
    show: function () {
      axios.get(this.endpoint + `?provider=${this.value}`).then((res) => {
        this.users = res.data.results
        this.previousPage = res.data.previous
        this.nextPage = res.data.next
      })
    },
    deleteUser: function (id) {
      const result = confirm("Tem certeza que deseja deletar?");
      if (result == true) {
        axios.delete(this.endpoint + id).then(() => {
          this.users = this.users.filter(procedure => procedure.id != id)
        })
      } else {
        console.log("cancelado")
      }
    },
    navegate: function (page) {
      axios.get(page).then((res) => {
        this.users = res.data.results
        this.previousPage = res.data.previous
        this.nextPage = res.data.next
      })

    },
    doSearch: function () {
      if (this.search.length == 0) {
        axios.get(this.endpoint).then((res) => {
          this.users = res.data.results
        })
      }
      if (this.search.length > 3) {
        console.log(this.search)
        axios.get(this.endpoint + `?name=${this.search}`).then((res) => {
          this.users = res.data.results
        })

      }
    }
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
};
</script>
<style>
.text-red-600 {
  background-color: #dc2626;
}
</style>
