<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12 px-4">
      <div class="flex flex-col">
        <div v-if="alertOpen" class="text-white px-6 py-4 rounded relative mb-4 bg-emerald-500">
          <span class="text-xl inline-block mr-5 align-middle">
            <i class="fas fa-check"></i>
          </span>
          <span class="inline-block align-middle ml-2 mr-8">
            <b class="capitalize">Salvo!</b> informações atualizadas
          </span>
          <button
            class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            v-on:click="closeAlert()">
            <span>×</span>
          </button>
        </div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{{ user.name }} {{ user.last_name }}</h6>
            <button @click="save"
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button">Salvar</button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informações gerais</h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Prestador</label>
                  <Multiselect placeholder="Pesquise por um prestador" noOptionsText="Nenhuma prestador"
                    v-model="user.provider" :filter-results="false" :min-chars="1" :resolve-on-load="false" :delay="0"
                    :searchable="true" :options="async function (query) {
                      return fetchClinics(query) // check JS block for implementation
                    }" />
                  <p class="help is-danger" v-for="error in errors.name" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Nome</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="user.name" v-bind:class="{ 'has-error': errors.name }" />
                  <p class="help is-danger" v-for="error in errors.name" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Sobrenome</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="user.last_name" v-bind:class="{ 'has-error': errors.last_name }" />
                  <p class="help is-danger" v-for="error in errors.last_name" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-9/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Email</label>
                  <input type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="user.email" v-bind:class="{ 'has-error': errors.email }" />
                  <p class="help is-danger" v-for="error in errors.email" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Administrador</label>
                  <input type="checkbox"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    v-model="user.is_admin" v-bind:class="{ 'has-error': errors.active }" />
                  <p class="help is-danger" v-for="error in errors.active" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">senha</label>
                  <input type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="user.password" v-bind:class="{ 'has-error': errors.password }" />
                  <p class="help is-danger" v-for="error in errors.password" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">confirme a
                    senha</label>
                  <input type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="user.confirm_password" v-bind:class="{ 'has-error': errors.confirm_password }" />
                  <p class="help is-danger" v-for="error in errors.confirm_password" :key="error">{{ error }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import _ from 'lodash'
import axios from '@/axios'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      endpoint: "users-provider/",
      userId: "",
      user: {},
      errors: {},
      alertOpen: false,
    }
  },
  methods: {
    fetchClinics: _.debounce(async function (query) {
      if (query) {
        const response = await axios.get("/providers/?name=" + query)
        return response.data.results.map((item) => {
          return { value: item.id, label: item.name }
        })
      }
    }, 200),
    closeAlert: function () {
      this.alertOpen = false;
    },
    save: function () {
      if (this.user.password != this.user.confirm_password) {
        this.errors["confirm_password"] = ["Senhas não conferem"];
        return;
      }
      delete this.user.confirm_password;
      axios.post(this.endpoint, { ...this.user })
        .then((res) => {
          this.userId = res.data.id
          this.errors = {}
          this.alertOpen = true
          setTimeout(() => {
            this.alertOpen = false
            this.$router.push({ name: 'user-provider-details', params: { id: this.userId } })
          }, 2000);

        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errors = err.response.data
          }
        })
    }
  },
  created() {
    // this.userId = this.$route.params.id
    // axios.get(`user/${this.userId}/`).then((res) => {
    //   this.user = res.data
    // })
  }
};
</script>
<style>
.is-danger {
  color: #eb254a;
}

.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
