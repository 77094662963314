function baseUrl() {
  if (location.host.includes("staging")) {
    return process.env.VUE_APP_API_URL_STAGING
  } else {
    return process.env.VUE_APP_API_URL
  }
}

export const BASE_URL = baseUrl()

