import { createApp } from "vue";

// styles
import "vue2-animate/dist/vue2-animate.min.css"
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "@vueform/multiselect/themes/default.css";
import router from './router'
import user from './store/UserStore'
import App from "@/App.vue";

import { IMaskDirective } from 'vue-imask'
import { IMaskComponent } from 'vue-imask';
import { Money3Component } from 'v-money3'

const app = createApp(App)
app.use(router)
app.directive('mask', IMaskDirective)
app.component("money3", Money3Component)
app.component("imask", IMaskComponent)

app.use(user)
app.mount("#app")
