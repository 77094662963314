import { createWebHistory, createRouter } from "vue-router";
// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import ProviderDetails from "@/views/admin/ProviderDetails.vue";
import ClinicDetails from "@/views/admin/ClinicDetails.vue";
import ProcedureDetails from "@/views/admin/ProcedureDetails.vue";
import ProviderNew from "@/views/admin/ProviderNew.vue";

import ProcedureNew from "@/views/admin/ProcedureNew.vue";
import ClinicNew from "@/views/admin/ClinicNew.vue";
import UserClinicNew from "@/views/admin/UserClinicNew.vue";
import Patients from "@/views/admin/Patients.vue";
import PatientDetails from "@/views/admin/PatientDetails.vue";
import PatientNew from "@/views/admin/PatientNew.vue";
import Providers from "@/views/admin/Providers.vue";
import ProceduresProviders from "@/views/admin/ProceduresProvider.vue";
import ProcedureProviderDetails from "@/views/admin/ProcedureProviderDetails.vue";
import ProcedureProviderNew from "@/views/admin/ProcedureProviderNew.vue";
import UsersClinic from "@/views/admin/UsersClinic.vue";
import UsersProvider from "@/views/admin/UsersProviders.vue";
import UsersProviderNew from "@/views/admin/UsersProviderNew.vue";
import UserProviderDetails from "@/views/admin/UserProviderDetails.vue";
import UserClinicDetails from "@/views/admin/UserClinicDetails.vue";
import Clinics from "@/views/admin/Clinics.vue";
import Procedures from "@/views/admin/Procedures.vue";
import InvoicesProvider from "@/views/admin/InvoicesProvider.vue";
import InvoicesClinic from "@/views/admin/InvoicesClinic.vue";
import InvoicesUserClinic from "@/views/admin/InvoicesUserClinic.vue";
import InvoicesUserClinicDetails from "@/views/admin/InvoiceUserClinicDetails.vue";
import InvoicesProviderDetails from "@/views/admin/InvoiceProviderDetails.vue";
import InvoicesClinicDetails from "@/views/admin/InvoiceClinicDetails.vue";

import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

// import Landing from "@/views/Landing.vue";
// import Profile from "@/views/Profile.vue";
// import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/prestadores",
        component: Providers,
      },
      {
        path: "/admin/prestadores/:id",
        name: "providerDetails",
        component: ProviderDetails,
      },
      {
        path: "/admin/prestadores/novo",
        name: "providerNew",
        component: ProviderNew,
      },
      {
        path: "/admin/cobrancas-provedor",
        name: "InvoiceProvedor",
        component: InvoicesProvider,
      },
      {

        path: "/admin/detalhe-cobranca-provedor/:id",
        name: "InvoiceProviderDetails",
        component: InvoicesProviderDetails,
      },
      {
        path: "/admin/cobranca-clinica",
        name: "InvoiceClinic",
        component: InvoicesClinic,
      },
      {
        path: "/admin/detalhe-cobranca-clinica/:id",
        name: "InvoiceClinicDetails",
        component: InvoicesClinicDetails,
      },
      {
        path: "/admin/cobranca-usuario-clinica",
        name: "InvoiceUserClinic",
        component: InvoicesUserClinic,
      },
      {
        path: "/admin/detalhe-cobranca-usuario-clinica/:id",
        name: "InvoiceUserClinicDetails",
        component: InvoicesUserClinicDetails,

      },
      {
        path: "/admin/clinicas",
        component: Clinics,
        name: "clinics"
      },
      {
        path: "/admin/usuarios-clinica/:id",
        name: "user-clinic-details",
        component: UserClinicDetails,
      },
      {
        path: "/admin/usuarios-clinica/novo",
        name: "user-clinic-new",
        component: UserClinicNew,
      },
      {
        path: "/admin/usuarios-clinica",
        component: UsersClinic,
        name: "user-clinic"
      },
      {
        path: "/admin/usuarios-provedores",
        component: UsersProvider,
        name: "user-provider"
      },
      {
        path: "/admin/usuarios-provedores/novo",
        component: UsersProviderNew,
        name: "user-provider-new"
      },
      {
        path: "/admin/usuarios-provedores/:id",
        component: UserProviderDetails,
        name: "user-provider-details"
      },
      {
        path: "/admin/procedimentos-provedor",
        component: ProceduresProviders,
        name: "procedures-provider"
      },
      {
        path: "/admin/procedimentos-provedor/:id",
        component: ProcedureProviderDetails,
        name: "procedures-provider-details"
      },
      {
        path: "/admin/procedimentos-provedor/novo",
        component: ProcedureProviderNew,
        name: "procedures-provider-new"
      },
      {
        path: "/admin/clinicas/:id",
        name: "clinicDetails",
        component: ClinicDetails,
      },
      {
        path: "/admin/clinica/novo",
        name: "clinicNew",
        component: ClinicNew,
      },
      {
        path: "/admin/procedimentos",
        component: Procedures,
        name: "procedures"
      },
      {
        path: "/admin/procedimentos/novo",
        component: ProcedureNew,
        name: "procedureNew"
      },
      {
        path: "/admin/procedimentos/:id",
        name: "procedureDetails",
        component: ProcedureDetails,
      },
      {
        path: "/admin/pacientes",
        component: Patients,
        name: "patients",
      },
      {
        path: "/admin/pacientes/:id",
        name: "patientDetails",
        component: PatientDetails,
      },
      {
        path: "/admin/pacientes/novo",
        component: PatientNew,
        name: "patientNew"
      },
      {
        path: "/admin/maps",
        component: Maps,
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  // {
  //   path: "/landing",
  //   component: Landing,
  // },
  // {
  //   path: "/profile",
  //   component: Profile,
  // },
  // {
  //   path: "/",
  //   component: Index,
  // },
  { path: "/:pathMatch(.*)*", redirect: "/auth/login" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('access_token');
  if (authRequired && !loggedIn) {
    next('/auth/login');
  } else {
    next();
  }
});

export default router;
