<template>
  <div>
    <div class="flex flex-col">
      <div v-if="alertOpen" class="text-white px-6 py-4 rounded relative mb-4 bg-emerald-500">
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-check"></i>
        </span>
        <span class="inline-block align-middle ml-2 mr-8">
          <b class="capitalize">Salvo!</b> informações atualizadas
        </span>
        <button
          class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
          v-on:click="closeAlert()">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">{{ patient.name }} {{ patient.last_name }}</h6>
          <button @click="save"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button">Salvar</button>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informações gerais</h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Nome</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.name" v-bind:class="{ 'has-error': errors.name }" />
                <p class="help is-danger" v-for="error in errors.name" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Ultimo
                  Nome</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.last_name" v-bind:class="{ 'has-error': errors.last_name }" />
                <p class="help is-danger" v-for="error in errors.last_name" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Email</label>
                <input type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.email" v-bind:class="{ 'has-error': errors.email }" />
                <p class="help is-danger" v-for="error in errors.email" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Data de
                  nascimento</label>
                <input type="text" v-mask="{ mask: '00/00/0000' }"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.birth_date" v-bind:class="{ 'has-error': errors.date }" />
                <p class="help is-danger" v-for="error in errors.date" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">CPF</label>
                  <input maxlength="14" type="cpf" v-mask="{ mask: '000.000.000-00' }"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.cpf" v-bind:class="{ 'has-error': errors.cpf }" />
                  <p class="help is-danger" v-for="error in errors.cpf" :key="error">{{ error }}</p>
                </div>
              </div>
            </div>
            <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">RG</label>
                <input maxlength="9" type="text" v-mask="{ mask: '000000000' }"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.rg" v-bind:class="{ 'has-error': errors.rg }" />
                <p class="help is-danger" v-for="error in errors.rg" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Cidade</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.city" v-bind:class="{ 'has-error': errors.city }" />
                <p class="help is-danger" v-for="error in errors.city" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Bairro</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.district" v-bind:class="{ 'has-error': errors.district }" />
                <p class="help is-danger" v-for="error in errors.district" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Número de
                  celular</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.number" v-bind:class="{ 'has-error': errors.number }" />
                <p class="help is-danger" v-for="error in errors.number" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Estado</label>
                <select
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.state">
                  <option v-for="state in states" :key="state.sigla" :value="state.sigla">{{ state.nome }}</option>
                </select>
                <p class="help is-danger" v-for="error in errors.state" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Rua</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.street" v-bind:class="{ 'has-error': errors.street }" />
                <p class="help is-danger" v-for="error in errors.street" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-3" for="grid-password">Caixa
                  postal</label>
                <input type="text" v-mask="{ mask: '00000-000' }"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="patient.zip_code" v-bind:class="{ 'has-error': errors.zip_code }" />
                <p class="help is-danger" v-for="error in errors.zip_code" :key="error">{{ error }}</p>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from '@/axios'
import states from '@/store/BrazilianStates'
export default {

  data() {
    return {
      patientId: "",
      patient: {},
      errors: {},
      alertOpen: false,
      states: states
    }
  },
  methods: {
    closeAlert: function () {
      this.alertOpen = false;
    },
    save: function () {
      axios.post(`patients/`, this.patient)
        .then((res) => {
          this.patientId = res.data.id
          this.errors = {}
          this.alertOpen = true
          setTimeout(() => {
            this.alertOpen = false
            this.$router.push({ name: 'patientDetails', params: { id: this.patientId } })
          }, 2000);

        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errors = err.response.data
          }
        })
    },
  },

  created() {
    // this.patientId = this.$route.params.id
    // axios.get(`patient/${this.patientId}/`).then((res) => {
    //   this.patient = res.data
    // })
  },
};
</script>
<style>
.is-danger {
  color: #eb254a;
}

.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
