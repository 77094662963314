<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12 px-4">
      <div class="flex flex-col">
        <div v-if="alertOpen" class="text-white px-6 py-4 rounded relative mb-4 bg-emerald-500">
          <span class="text-xl inline-block mr-5 align-middle">
            <i class="fas fa-check"></i>
          </span>
          <span class="inline-block align-middle ml-2 mr-8">
            <b class="capitalize">Atualizado!</b> informações atualizadas
          </span>
          <button
            class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            v-on:click="closeAlert()"
          >
            <span>×</span>
          </button>
        </div>
      </div>
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      >
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{{ procedure.name }}</h6>
            <button
              @click="save"
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >Atualizar</button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informações</h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >Nome</label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="procedure.name"
                    v-bind:class="{ 'has-error': errors.name }"
                  />
                  <p class="help is-danger" v-for="error in errors.name" :key="error">{{ error }}</p>
                </div>
              </div>
            </div>

            <hr class="mt-6 border-b-1 border-blueGray-300" />

            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Instruções</h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >Instrucões</label>
                  <textarea
                    v-model="procedure.instructions"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password"
                  >Notas</label>
                  <textarea
                    v-model="procedure.notes"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from '@/axios'
import states from '@/store/BrazilianStates'

export default {
  data() {
    return {
      endpoint: "procedures/",
      procedureId: "",
      procedure: {},
      errors: {},
      alertOpen: false,
      states: states
    }
  },
  methods: {
    closeAlert: function () {
      this.alertOpen = false;
    },
    save: function () {
      axios.put(this.endpoint + this.procedureId + "/", { ...this.procedure })
        .then(() => {
          this.errors = {}
          this.alertOpen = true
          setTimeout(() => {
            this.alertOpen = false
          }, 2000);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errors = err.response.data
          }
        })
    }
  },
  created() {
    this.procedureId = this.$route.params.id
    axios.get(this.endpoint + this.procedureId + "/").then((res) => {
      this.procedure = res.data
    })
  }
};
</script>
<style>
.is-danger {
  color: #eb254a;
}
.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
