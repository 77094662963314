<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <card-clinics />
    </div>
  </div>
</template>
<script>
import CardClinics from "@/components/Cards/CardClinics.vue"

export default {
  components: {
    CardClinics,
  },
};
</script>
