<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12 px-4">
      <div class="flex flex-col">
        <div v-if="alertOpen" class="text-white px-6 py-4 rounded relative mb-4 bg-emerald-500">
          <span class="text-xl inline-block mr-5 align-middle">
            <i class="fas fa-check"></i>
          </span>
          <span class="inline-block align-middle ml-2 mr-8">
            <b class="capitalize">Atualizado!</b> informações atualizadas
          </span>
          <button
            class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            v-on:click="closeAlert()">
            <span>×</span>
          </button>
        </div>
      </div>
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{{ patient.name }} {{ patient.last_name }}</h6>
            <button @click="save"
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button">Atualizar</button>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
          <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informações</h6>
            <div class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Nome</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.name" v-bind:class="{ 'has-error': errors.name }" />
                  <p class="help is-danger" v-for="error in errors.name" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Sobrenome</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.last_name" v-bind:class="{ 'has-error': errors.last_name }" />
                  <p class="help is-danger" v-for="error in errors.last_name" :key="error">{{ error }}</p>
                </div>
                <div cclass="flex-auto px-4 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-password">Email</label>
                    <input type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="patient.email" v-bind:class="{ 'has-error': errors.email }" />
                    <p class="help is-danger" v-for="error in errors.email" :key="error">{{ error }}</p>
                  </div>
                </div>
                <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Data de
                      nascimento</label>
                    <input type="text" v-mask="{ mask: '00/00/0000' }"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="patient.birth_date" v-bind:class="{ 'has-error': errors.date }" />
                    <p class="help is-danger" v-for="error in errors.date" :key="error">{{ error }}</p>
                  </div>
                </div>
                <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-password">CPF</label>
                    <input maxlength="14" type="cpf" v-mask="{ mask: '000.000.000-00' }"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="patient.cpf" v-bind:class="{ 'has-error': errors.cpf }" />
                    <p class="help is-danger" v-for="error in errors.cpf" :key="error">{{ error }}</p>
                  </div>
                </div>
                <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="grid-password">RG</label>
                    <input maxlength="10" type="number" v-mask="{ mask: '000000000' }"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="patient.rg" v-bind:class="{ 'has-error': errors.rg }" />
                    <p class="help is-danger" v-for="error in errors.rg" :key="error">{{ error }}</p>
                  </div>
                </div>
                <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Celular
                      1</label>
                    <input type="text" v-mask="{ mask: '(00) 0000-00000' }"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="patient.cellphone1" v-bind:class="{ 'has-error': errors.cellphone1 }" />
                    <p class="help is-danger" v-for="error in errors.cellphone1" :key="error">{{ error }}</p>
                  </div>
                </div>
                <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Celular
                      2</label>
                    <input type="text" v-mask="{ mask: '(00) 0000-00000' }"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="patient.cellphone2" v-bind:class="{ 'has-error': errors.cellphone2 }" />
                    <p class="help is-danger" v-for="error in errors.cellphone2" :key="error">{{ error }}</p>
                  </div>
                </div>
                <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Telefone
                      Residencial 1</label>
                    <input type="text" v-mask="{ mask: '(00) 0000-00000' }"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="patient.phone1" v-bind:class="{ 'has-error': errors.phone1 }" />
                    <p class="help is-danger" v-for="error in errors.phone1" :key="error">{{ error }}</p>
                  </div>
                </div>
                <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
                  <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Telefone
                      Residencial 2</label>
                    <input type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-mask="{ mask: '(00) 0000-00000' }" v-model="patient.phone2"
                      v-bind:class="{ 'has-error': errors.phone2 }" />
                    <p class="help is-danger" v-for="error in errors.phone2" :key="error">{{ error }}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informação de Endereço</h6>
            <div class="flex-auto px-1 lg:px-5 py-5 pt-0">
              <div class="w-full lg:w-8/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Lagradouro</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.street" v-bind:class="{ 'has-error': errors.street }" />
                  <p class="help is-danger" v-for="error in errors.street" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Numero</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.number" v-bind:class="{ 'has-error': errors.number }" />
                  <p class="help is-danger" v-for="error in errors.number" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Bairro</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.district" v-bind:class="{ 'has-error': errors.district }" />
                  <p class="help is-danger" v-for="error in errors.district" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Cidade</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.city" v-bind:class="{ 'has-error': errors.city }" />
                  <p class="help is-danger" v-for="error in errors.city" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Estado</label>
                  <select
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.state">
                    <option v-for="state in states" :key="state.sigla" :value="state.sigla">{{ state.nome }}</option>
                  </select>
                  <p class="help is-danger" v-for="error in errors.state" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Caixa
                    postal</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.zip_code" v-bind:class="{ 'has-error': errors.zip_code }" />
                  <p class="help is-danger" v-for="error in errors.zip_code" :key="error">{{ error }}</p>
                </div>
              </div>
              <div class="flex-auto px-4 lg:px-5 py-5 pt-0">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-password">Complemento</label>
                  <input type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="patient.complement" v-bind:class="{ 'has-error': errors.complement }" />
                  <p class="help is-danger" v-for="error in errors.complement" :key="error">{{ error }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from '@/axios'
import states from '@/store/BrazilianStates'

export default {
  data() {
    return {
      endpoint: "patients/",
      patientId: "",
      patient: {},
      errors: {},
      alertOpen: false,
      states: states,
    }
  },
  methods: {
    closeAlert: function () {
      this.alertOpen = false;
    },
    save: function () {

      const cpf = parseInt(this.patient.cpf.split('.').join('').split('-').join(''));
      axios.put(this.endpoint + this.patientId + "/", { ...this.patient, cpf })
        .then(() => {
          this.errors = {}
          this.alertOpen = true
          setTimeout(() => {
            this.alertOpen = false
          }, 2000);
        })

        .catch((err) => {
          if (err.response.status == 400) {
            this.errors = err.response.data
          }
        })
    }
  },
  created() {
    this.patientId = this.$route.params.id
    axios.get(this.endpoint + this.patientId + "/").then((res) => {
      this.patient = res.data
    })
  }
};
</script>
<style>
.is-danger {
  color: #eb254a;
}

.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
