<template>
  <div v-if="isLoading" class="flex justify-center items-center m-8">
    <span class="text-gray-600 text-sm">
      <i class="fas fa-spinner fa-spin"></i>
           Carregando...
    </span>
  </div>
</template>
<script>
export default {
  name: 'Input',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
  },
}
</script>
