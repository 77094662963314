<template>
  <div>
    <div class="flex flex-col">
      <div v-if="alertOpen" class="text-white px-6 py-4 rounded relative mb-4 bg-emerald-500">
        <span class="text-xl inline-block mr-5 align-middle">
          <i class="fas fa-check"></i>
        </span>
        <span class="inline-block align-middle ml-2 mr-8">
          <b class="capitalize">Atualizado!</b> informações atualizadas
        </span>
        <button
          class="absolute bg-transparent text-2xl font-semibold leading-none px-6 right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
          v-on:click="closeAlert()">
          <span>×</span>
        </button>
      </div>
    </div>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">{{ provider.name }}</h6>
          <button @click="save"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button">Atualizar</button>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informações</h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Nome</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.name" v-bind:class="{ 'has-error': errors.name }" />
                <p class="help is-danger" v-for="error in errors.name" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Pagar a cada
                  dias</label>
                <input type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.config_invoice_date" v-bind:class="{ 'has-error': errors.config_invoice_date }" />
                <p class="help is-danger" v-for="error in errors.config_invoice_date" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-8/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Percentual</label>
                <input type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.percent" v-bind:class="{ 'has-error': errors.percent }" />
                <p class="help is-danger" v-for="error in errors.percent" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Ativo</label>
                <input type="checkbox"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                  v-model="provider.active" v-bind:class="{ 'has-error': errors.active }" />
                <p class="help is-danger" v-for="error in errors.active" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">RQE
                  responsável</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.responsible" v-bind:class="{ 'has-error': errors.responsible }" />
                <p class="help is-danger" v-for="error in errors.responsible" :key="error">{{ error }}</p>
              </div>
            </div>            
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Crm
                  Responsável</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.crm" v-bind:class="{ 'has-error': errors.crm }" />
                <p class="help is-danger" v-for="error in errors.crm" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">RQE
                  responsável</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.rqe" v-bind:class="{ 'has-error': errors.rqe }" />
                <p class="help is-danger" v-for="error in errors.rqe" :key="error">{{ error }}</p>
              </div>
            </div>
          </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informação de Contato</h6>
            
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Celular 1</label>
                <input type="text" v-mask="{ mask: '(00)00000-0000' }"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.cellphone1" v-bind:class="{ 'has-error': errors.cellphone1 }" />
                <p class="help is-danger" v-for="error in errors.cellphone1" :key="error">{{ error }}</p>
              </div>
            </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Celular 2</label>
                  <input type="text" v-mask="{ mask: '(00)00000-0000' }"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="provider.cellphone2" v-bind:class="{ 'has-error': errors.cellphone1 }" />
                  <p class="help is-danger" v-for="error in errors.cellphone2" :key="error">{{ error }}</p>
                </div>
              </div>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Telefone 1</label>
                <input type="text" v-mask="{ mask: '(00)00000-0000' }"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full  ease-linear transition-all duration-150"
                  v-model="provider.phone1" v-bind:class="{ 'has-error': errors.phone1 }" />
                <p class="help is-danger" v-for="error in errors.phone1" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Telefone 2</label>
                    <input
                      type="phone2" v-mask="{ mask: '(00)00000-0000' }"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full  ease-linear transition-all duration-150"
                      v-model="provider.phone2" v-bind:class="{ 'has-error': errors.phone2 }" />
                <p class="help is-danger" v-for="error in errors.phone2" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Email</label>
                <input type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.email" v-bind:class="{ 'has-error': errors.email }" />
                <p class="help is-danger" v-for="error in errors.email" :key="error">{{ error }}</p>
              </div>
            </div>
          <hr class="mt-6 border-b-1 border-blueGray-300" />

          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Informação de Endereço</h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Lagradouro</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.street" v-bind:class="{ 'has-error': errors.street }" />
                <p class="help is-danger" v-for="error in errors.street" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Numero</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.number" v-bind:class="{ 'has-error': errors.street }" />
                <p class="help is-danger" v-for="error in errors.street" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Bairro</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.district" v-bind:class="{ 'has-error': errors.district }" />
                <p class="help is-danger" v-for="error in errors.district" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Cidade</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.city" v-bind:class="{ 'has-error': errors.city }" />
                <p class="help is-danger" v-for="error in errors.city" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password">Estado</label>
                <select
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.state">
                  <option v-for="state in states" :key="state.sigla" :value="state.sigla">{{ state.nome }}</option>
                </select>
                <p class="help is-danger" v-for="error in errors.state" :key="error">{{ error }}</p>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-password">Caixa
                  postal</label>
                <input type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="provider.zip_code" v-bind:class="{ 'has-error': errors.zip_code }" />
                <p class="help is-danger" v-for="error in errors.zip_code" :key="error">{{ error }}</p>
              </div>
            </div>
          </div>

          <hr class="mt-6 border-b-1 border-blueGray-300" />
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from '@/axios'
import states from '@/store/BrazilianStates'

export default {
  data() {
    return {
      providerId: "",
      provider: {},
      errors: {},
      alertOpen: false,
      states: states
    }
  },
  methods: {
    closeAlert: function () {
      this.alertOpen = false;
    },
    save: function () {
      axios.put(`providers/${this.providerId}/`, { ...this.provider })
        .then(() => {
          this.errors = {}
          this.alertOpen = true
          setTimeout(() => {
            this.alertOpen = false
          }, 2000);
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.errors = err.response.data
          }
        })
    }
  },
  created() {
    this.providerId = this.$route.params.id
    axios.get(`providers/${this.providerId}/`).then((res) => {
      this.provider = res.data
    })
  }
};
</script>
<style>
.is-danger {
  color: #eb254a;
}

.has-error {
  color: #eb254a;
  border-color: #eb254a;
  border-width: 1px;
}
</style>
