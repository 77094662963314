<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <CardProviders />
    </div>
  </div>
</template>
<script>
import CardProviders from "@/components/Cards/CardProviders.vue"

export default {
  components: {
    CardProviders ,
  },
};
</script>
