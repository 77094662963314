<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12 px-4">
      <CardNewProvider />
    </div>
  </div>
</template>
<script>
import CardNewProvider from "@/components/Cards/CardNewProvider.vue";

export default {
  components: {
    CardNewProvider,
  },
  created() {
  }
};
</script>
