<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12 px-4">
      <CardProvider />
    </div>
  </div>
</template>
<script>
import CardProvider from "@/components/Cards/CardProvider.vue";

export default {
  components: {
    CardProvider,
  },
  created() {
  }
};
</script>
